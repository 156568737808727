import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții personalizate</span>
                            <h3>Despre trainingurile personalizate</h3>
                              <p>Trainingurile tehnice sunt programe educaționale destinate ajutorării profesioniștilor să își dezvolte abilități tehnice și cunoștințe într-un anumit domeniu. La Vertical Digital, oferim o gamă largă de traininguri tehnice pentru profesioniștii din diferite industrii. Trainingurile noastre tehnice sunt concepute pentru a fi interactive, practice și adaptate nevoilor specifice ale fiecărui participant.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                        <p>Procesul nostru de training tehnic începe cu o evaluare a nevoilor de training ale organizației dvs. Lucrăm cu dvs. pentru a identifica abilitățile tehnice și cunoștințele de care angajații dvs. au nevoie să își dezvolte, și proiectăm un program de training care îndeplinește aceste nevoi.</p>
                                        <p>În timpul trainingului, instructorii noștri experimentați oferă instruire practică, interactive, pentru a ajuta participanții să își dezvolte abilitățile și cunoștințele de care au nevoie. De asemenea, oferim suport și resurse de urmărire pentru a ajuta participanții să aplice ceea ce au învățat la locul de muncă.</p>
                                    </div>
                                </div>
                            </div>

                            <p>La Vertical Digital, suntem dedicați ajutorării profesioniștilor să își dezvolte abilitățile tehnice și cunoștințele de care au nevoie pentru a reuși în carierele lor. Trainingurile noastre tehnice sunt concepute pentru a fi interactive, practice și adaptate nevoilor specifice ale fiecărui participant. Contactați-ne astăzi pentru a afla mai multe despre cum vă putem ajuta organizația să reușească prin traininguri tehnice.</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>Java</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Kotlin</span></li>
                                <li><span>SQL</span></li>
                                <li><span>Docker</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent